export const DebugModule = {
    namespaced: true,
    state: {
        toastType: "none",
    },
    mutations: {
        // UPDATE_TOAST_TYPE(commit, payload) {
        //     commit("ToastModule/")
        // },
    },
};
