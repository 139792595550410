import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    redirect: () => {
      return "/MyDataset/0";
    },
    children: [
      { path: "/MyDataset/:id", name: "mydataset" },
      { path: "/ShareWithMe/:id", name: "sharewithme" },
      {
        path: "/aprovedata/",
        name: "approvedata",
        children: [{ path: "/aprovedata/:id" }],
      },
      {
        path: "/share-with-link/",
        name: "share-with-link",
        props: (route) => ({ query: route.query.code }),
      },
    ],
  },
  {
    path: "/callback",
    name: "CallBack",
    component: () => import("../views/Callback.vue"),
  },
  {
    path: "/DataDictionary/:id",
    name: "datadictionary",
    component: () => import("../views/Datadictionary.vue"),
  },
  {
    path: "/search",
    name: "search",
    component: () => import("../views/Home.vue"),
    props: (route) => ({ query: route.query.q }),
    children: [{ path: "/search/:id" }],
  },
  {
    path: "/FileViewer/:id",
    name: "fileviewer",
    component: () => import("../views/TextViewer.vue"),
  },
  {
    path: "*",
    name: "404",
    component: () => import("../views/PageNotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
