export const ToastModule = {
    namespaced: true,
    state: {
        timerToastStackDelay: 480,
        timerToastVisibleDelay: 6500, //Sync  with css Toast progress animation time
        isVisible: false,
        timeCount: null,
        defaultToast: {
            type: "none",
            title: "Default Title",
            body: "Ut tempor eiusmod laborum est duis cillum aliqua Lorem culpa amet ad. Eiusmod labore consectetur officia ex minim mollit non deserunt sunt aliquip duis commodo est est.",
            timerToastVisibleDelay: 6500,
        },
        type: "none",
        title: "Default Title",
        body: "Ut tempor eiusmod laborum est duis cillum aliqua Lorem culpa amet ad. Eiusmod labore consectetur officia ex minim mollit non deserunt sunt aliquip duis commodo est est.",
    },
    getters: {
        getToastType: (state) => state.type,
        getToastDelayInSeconds: (state) => state.timerToastVisibleDelay / 1000,
        getContentTitle: (state) => state.title,
        getContentBody: (state) => state.body,
    },
    mutations: {
        SET_TOAST_VISIBLE(state, payload) {
            state.isVisible = payload;
        },
        SET_TIME_COUNT(state, payload) {
            state.timeCount = payload;
        },
        CLEAR_TIME_COUNT(state) {
            state.isVisible = false;
            clearTimeout(state.timeCount);
        },
        SET_CONTENT(state, payload) {
            state.type = payload.type || state.defaultToast.type;
            state.title = payload.title || state.defaultToast.title;
            state.body = payload.body || state.defaultToast.body;
            state.timerToastVisibleDelay =
                payload.delay || state.defaultToast.timerToastVisibleDelay;
        },
    },
    actions: {
        setToastVisible({ commit, dispatch, state }) {
            if (localStorage.getItem("token")) {
                commit("CLEAR_TIME_COUNT");
                setTimeout(() => {
                    commit("SET_TOAST_VISIBLE", true);
                    commit(
                        "SET_TIME_COUNT",
                        setTimeout(() => {
                            dispatch("setToastInvisible");
                        }, state.timerToastVisibleDelay)
                    );
                }, state.timerToastStackDelay);
            }
        },
        setToastInvisible({ commit }) {
            commit("SET_TOAST_VISIBLE", false);
        },
        addToast({ commit, dispatch }, data) {
            commit("SET_CONTENT", data);
            dispatch("setToastVisible");
        },
    },
};
