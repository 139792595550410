<template>
    <v-app>
        <DebugTools v-if="false" />
        <Toast />
        <router-view />
    </v-app>
</template>

<script>
import { mapState } from "vuex";
import Toast from "./components/Alert/Toast/Toast.vue";
import DebugTools from "./components/DebugTools/DebugTools.vue";

export default {
    name: "App",
    components: { Toast, DebugTools },
    computed: {
        ...mapState({
            isLogin: (state) => state.isLogin,
        }),
    },
    created() {
        this.$store.dispatch("getConfig");
    },
    mounted() {
        const check = localStorage.getItem("token");
        if (check) {
            this.$store.commit("SET_LOGIN");
            // if (this.$store.state.tags.length === 0) {

            // }
        } else {
            this.$store.commit("SET_LOGOUT");
        }
    },
};
</script>

<style>
@import url("./assets/style/main.scss");
@font-face {
    font-family: "prompt";
    src: url(./fonts/Prompt-Regular.ttf) format("truetype");
}

/* mac web scroll */
::-webkit-scrollbar {
    background: transparent;
}
::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 4px solid transparent;
    background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}

* {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: "prompt";
    font-weight: normal !important;
}
html {
    display: unset !important;
    margin: 0 !important;
    overflow-y: auto !important;
}
body {
    display: unset !important;
}
</style>
