<template>
    <div
        class="ds-toast"
        :class="[{ active: isVisible }, toastType]"
        :style="{ '--default-delay': `${delay}s` }"
    >
        <div class="toast-label" :class="[toastType]"></div>
        <div class="toast-icon">
            <i class="icon-layout">
                <svg
                    v-if="toastType === 'warning' || toastType === 'danger'"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M11.8649 10.3128C12.2495 11.0627 11.7668 12 10.9988 12H1.00113C0.23161 12 -0.248848 11.0612 0.134943 10.3128L5.13382 0.562149C5.51855 -0.188062 6.48215 -0.186703 6.86619 0.562149L11.8649 10.3128V10.3128ZM6.00001 8.29688C5.47074 8.29688 5.04167 8.77957 5.04167 9.375C5.04167 9.97043 5.47074 10.4531 6.00001 10.4531C6.52928 10.4531 6.95834 9.97043 6.95834 9.375C6.95834 8.77957 6.52928 8.29688 6.00001 8.29688ZM5.09015 4.42158L5.24469 7.60908C5.25192 7.75823 5.36155 7.875 5.49432 7.875H6.50569C6.63847 7.875 6.74809 7.75823 6.75532 7.60908L6.90986 4.42158C6.91767 4.26047 6.80365 4.125 6.66024 4.125H5.33976C5.19634 4.125 5.08234 4.26047 5.09015 4.42158V4.42158Z"
                        fill="white"
                    />
                </svg>
                <svg
                    v-else-if="toastType === 'success'"
                    width="12"
                    height="11"
                    viewBox="0 0 12 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M4.07573 10.784L0.175729 5.98988C-0.0585762 5.70186 -0.0585762 5.23487 0.175729 4.94682L1.02424 3.90377C1.25854 3.61572 1.63846 3.61572 1.87277 3.90377L4.49999 7.13326L10.1272 0.216014C10.3615 -0.0720048 10.7414 -0.0720048 10.9758 0.216014L11.8243 1.25907C12.0586 1.54709 12.0586 2.01408 11.8243 2.30213L4.92426 10.784C4.68993 11.072 4.31003 11.072 4.07573 10.784Z"
                        fill="white"
                    />
                </svg>
                <svg
                    v-else
                    width="6"
                    height="12"
                    viewBox="0 0 6 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M0.625 9.94287H1.25V6.55713H0.625C0.279813 6.55713 0 6.34727 0 6.08838V4.96875C0 4.70986 0.279813 4.5 0.625 4.5H4.125C4.47019 4.5 4.75 4.70986 4.75 4.96875V9.94287H5.375C5.72019 9.94287 6 10.1527 6 10.4116V11.5312C6 11.7901 5.72019 12 5.375 12H0.625C0.279813 12 0 11.7901 0 11.5312V10.4116C0 10.1527 0.279813 9.94287 0.625 9.94287ZM3 0C1.75734 0 0.75 0.755508 0.75 1.6875C0.75 2.61949 1.75734 3.375 3 3.375C4.24266 3.375 5.25 2.61949 5.25 1.6875C5.25 0.755508 4.24262 0 3 0Z"
                        fill="white"
                    />
                </svg>
            </i>
        </div>
        <div class="toast-content">
            <span class="toast-title">{{ contentTitle }}</span>
            <span class="toast-body">{{ contentBody }}</span>
        </div>
        <button class="toast-close" @click="onClickCloseToast()">
            <i class="icon-wrapper">
                <svg
                    width="12"
                    height="12"
                    viewBox="0 0 9 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M5.68875 4.125L8.03414 1.77961C8.32195 1.4918 8.32195 1.02516 8.03414 0.737109L7.51289 0.215859C7.22508 -0.0719531 6.75844 -0.0719531 6.47039 0.215859L4.125 2.56125L1.77961 0.215859C1.4918 -0.0719531 1.02516 -0.0719531 0.737109 0.215859L0.215859 0.737109C-0.0719531 1.02492 -0.0719531 1.49156 0.215859 1.77961L2.56125 4.125L0.215859 6.47039C-0.0719531 6.7582 -0.0719531 7.22484 0.215859 7.51289L0.737109 8.03414C1.02492 8.32195 1.4918 8.32195 1.77961 8.03414L4.125 5.68875L6.47039 8.03414C6.7582 8.32195 7.22508 8.32195 7.51289 8.03414L8.03414 7.51289C8.32195 7.22508 8.32195 6.75844 8.03414 6.47039L5.68875 4.125Z"
                        fill="#AAAAAA"
                    />
                </svg>
            </i>
        </button>
        <div class="progress" :class="[{ active: isVisible }, toastType]"></div>
    </div>
</template>
<script>
// TODO:  Move fn from store to component
// TODO: Define it to core or share component
import "./toast.css";
import { mapState, mapGetters } from "vuex";

export default {
    name: "Toast",
    data: () => ({
        // isVisible: false,
    }),
    computed: {
        ...mapState({
            isVisible: (state) => state.ToastModule.isVisible,
        }),
        ...mapGetters({
            delay: "ToastModule/getToastDelayInSeconds",
            toastType: "ToastModule/getToastType",
            contentTitle: "ToastModule/getContentTitle",
            contentBody: "ToastModule/getContentBody",
        }),
    },
    methods: {
        onClickCloseToast() {
            this.$store.dispatch("ToastModule/setToastInvisible", {
                root: true,
            });
        },
    },
};
</script>
