<template>
    <div class="ds-debug">
        <div class="item">
            <button
                class="test-button"
                :class="{ active: isVisible }"
                @click="debugToastVisible()"
            >
                Toast: {{ isVisible }}
            </button>
        </div>
        <div class="item">
            <select
                v-model="toastType"
                class="test-select"
                name="toast-type"
                id="toast-type"
            >
                <option value="none" selected>none</option>
                <option value="info">info</option>
                <option value="success">success</option>
                <option value="danger">danger</option>
                <option value="warning">warning</option>
            </select>
        </div>
        <div class="item">
            <button @click="getAccountMe()">get account me</button>
        </div>
        <div class="item">
            <button @click="debugToastSetVisible()">Toast force visible</button>
        </div>
        <div class="item">E</div>
        <div class="item">F</div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { Axios_API } from "../../api";

export default {
    name: "Debug-tool",
    computed: {
        toastType: {
            get() {
                return this.$store.state.DebugModule.toastType;
            },
            set(val) {
                return this.$store.commit("ToastModule/SET_CONTENT", {
                    type: val,
                });
            },
        },
        ...mapState({
            isVisible: (state) => state.ToastModule.isVisible,
        }),
    },
    methods: {
        debugToastVisible() {
            console.log("button click");
            if (this.isVisible) {
                this.$store.dispatch("ToastModule/setToastInvisible", {
                    root: true,
                });
            } else {
                this.$store.dispatch("ToastModule/setToastVisible", {
                    root: true,
                });
            }
        },
        debugToastSetVisible() {
            this.$store.dispatch("ToastModule/setToastVisible", {
                root: true,
            });
        },
        getAccountMe() {
            Axios_API.get("/v2/account/me/").then((res) => {
                console.log("debug then:", res);
            });
            // .catch((res) => {
            //     console.log("debug catch:", res);
            // });
        },
    },
};
</script>

<style scope lang="scss">
.ds-debug {
    position: absolute;
    bottom: 24px;
    right: 24px;
    z-index: 1000;
    min-height: 100px;
    min-width: 200px;
    padding: 12px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    background-color: #fff;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
}

.ds-debug button {
    padding: 4px;
    border: 1px solid #000;
    border-radius: 4px;
    cursor: pointer;

    &:active {
        background-color: lightblue;
    }
}

.test-button {
    background-color: lightcoral;
    padding: 4px;
    border: 1px solid #000;
    border-radius: 4px;
    cursor: pointer;

    &.active {
        background-color: lightgreen;
    }
}

.test-select {
    padding: 4px;
    border: 1px solid #000;
    border-radius: 4px;
}
</style>
